var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right mt-5 mr-2"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "to": {
        name: 'pindaan.form-pindaan-harta-tidak-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("add-property-info")))]) : _vm._e()], 1), _vm.immoveable_properties_array && _vm.immoveable_properties_array.length == 0 ? _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("trust.no-prop")))])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.immoveable_properties_array, function (property, index) {
    return _c('div', {
      key: index,
      staticClass: "col-md-12"
    }, [_c('div', {
      staticClass: "pl-3 pr-3 mt-2"
    }, [_c('strong', [_c('router-link', {
      attrs: {
        "to": {
          name: 'pindaan.form-pindaan-harta-tidak-alih',
          params: {
            wasiat_id: _vm.wasiat_id
          },
          query: {
            property_id: property.id,
            pengesahan: _vm.pengesahan
          }
        }
      }
    }, [_vm._v(" " + _vm._s((_vm.pagination.currentPage - 1) * _vm.pagination.perPage + index + 1 + ". " + property.category) + " ")])], 1), _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("type-of-property")))]), _vm._v(": " + _vm._s(property.property_type) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("property-no")))]), _vm._v(": " + _vm._s(property.property_no) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("address")))]), _vm._v(": " + _vm._s(property.address) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("document")))]), _vm._v(": "), _c('router-link', {
      attrs: {
        "to": {
          name: 'pindaan.form-pindaan-dokumen-tidak-alih',
          params: {
            wasiat_id: _vm.wasiat_id
          },
          query: {
            property_id: property.id,
            pengesahan: _vm.pengesahan
          }
        }
      }
    }, [_vm._v(_vm._s(property.total_documents) + " " + _vm._s(_vm.$t("document")))])], 1)]), _c('div', {
      staticClass: "table-responsive mt-3 mb-4"
    }, [_c('table', {
      staticClass: "table table-sm"
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " / " + _vm._s(_vm.$t("passport")))]), _c('th', [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('th', [_vm._v(_vm._s(_vm.$t("part")))])])]), _c('tbody', [_vm._l(property.property_receivers, function (pr, index2) {
      var _pr$receiver, _pr$receiver2, _pr$receiver3, _pr$receiver4, _pr$receiver5;
      return _c('tr', {
        key: index2
      }, [_c('td', [_vm._v(_vm._s((_pr$receiver = pr.receiver) === null || _pr$receiver === void 0 ? void 0 : _pr$receiver.name))]), !((_pr$receiver2 = pr.receiver) !== null && _pr$receiver2 !== void 0 && _pr$receiver2.passport_number) ? _c('td', [_vm._v(_vm._s((_pr$receiver3 = pr.receiver) === null || _pr$receiver3 === void 0 ? void 0 : _pr$receiver3.ic_number))]) : _c('td', [_vm._v(_vm._s((_pr$receiver4 = pr.receiver) === null || _pr$receiver4 === void 0 ? void 0 : _pr$receiver4.passport_number))]), _c('td', [_vm._v(_vm._s((_pr$receiver5 = pr.receiver) === null || _pr$receiver5 === void 0 ? void 0 : _pr$receiver5.relationship))]), _c('td', [_vm._v(_vm._s(pr.portion))])]);
    }), property.property_receivers.length == 0 ? _c('tr', [_c('td', {
      staticClass: "text-center",
      attrs: {
        "colspan": "4"
      }
    }, [_c('h6', {
      staticClass: "mt-3 mb-3"
    }, [_vm._v(_vm._s(_vm.$t("trust.no-beneficiary")))])])]) : _vm._e()], 2)])])]), [0, 1, 4].includes(_vm.amendment_status) ? _c('div', {
      staticClass: "d-flex justify-content-end"
    }, [_c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteHarta(property, index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1) : _vm._e(), _c('hr', {
      staticClass: "primary"
    })]);
  }), 0), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }